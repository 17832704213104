import React from "react"

import addToMailchimp from "gatsby-plugin-mailchimp"
import { Formik, Field, Form} from "formik"
import * as Yup from 'yup'



const MailChimpForm = () => {
  
  const validationSchema = Yup.object().shape({
      firstName: Yup.string().required('Name is required'),
      email: Yup.string().email().required('E-mail is required'),
      acceptTerms: Yup.bool().oneOf([true], 'Accepting Terms & Conditions is required'),
      });
  
  return (
    <Formik
        validateOnChange={false}
        validateOnBlur={false}
      
        initialValues={{
        email: "",
        firstName: "",
        acceptTerms: false,
    }}
      validationSchema={validationSchema}
                           
      onSubmit={values => {
      addToMailchimp(values.email, {FNAME: values.firstName})
      .then(data => {
        if (data.result === "error") {
            document.getElementById("sendButton").style.backgroundColor="red";
            document.getElementById("sendButton").firstChild.data = "Already subscribed";
            setTimeout(function(){ 
                    document.getElementById("sendButton").style.backgroundColor="rgb(71,80,98)";
                    document.getElementById("sendButton").firstChild.data = "Send";
                    },3000);
            
        } else {
            document.getElementById("sendButton").style.backgroundColor="rgb(71,80,98)"
            document.getElementById("sendButton").firstChild.data = "Success";
            setTimeout(function(){ 
                    document.getElementById("sendButton").style.backgroundColor="#rgb(71,80,98)";
                    document.getElementById("sendButton").firstChild.data = "Send";
                    },3000);
      }})
    }
    }
    >
     {({ handleSubmit, handleChange, values, errors }) => (
    <Form onSubmit={handleSubmit}>
       <div className="field">
        <label id="yourName" className="label" htmlFor={'name'}>
            Your name
        </label>
      <div className="control">
      <input
        className="input"
        id="firstName"
        name="firstName"
        type="text"
        onChange={handleChange}
        value={values.firstName}
     
      />
          <span style={{color: 'red', marginTop: '5px', }}>  {errors.firstName} </span>
      </div>
      </div>
      
      <div className="field">
        <label id="eMail" className="label" htmlFor={'name'}>
            E-mail
        </label>
      <div className="control">
      <input
        className="input"
        id="email"
        name="email"
        type="email"
        onChange={handleChange}
        value={values.email}
      />
          <span style={{color: 'red', marginTop: '5px'}}>  {errors.email} </span>
      </div>
      </div>
     
       <Field type="checkbox" name="acceptTerms"  style={{marginTop: '25px'}}/>
       <label id="acceptTaC" className="label" htmlFor="acceptTerms" style={{display: 'inline', }}> Accept 
         <a href="/privacy-policy" target="_blank"> Terms & Conditions 
         </a>
         </label>
           <div className="control" style={{color: 'red', marginTop: '5px'}}> {errors.acceptTerms}  </div>
      
        <div className="field"> 
      <button id="sendButton" type="submit" className="button is-link">
        Send
      </button>
      </div>
    </Form>
    )}
</Formik>
  );
}

export default MailChimpForm